import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { defineNuxtPlugin } from "nuxt/app";
export default defineNuxtPlugin({
  name: "locomotive-scroll",
  setup({ vueApp }) {
    vueApp.directive("locomotive", {
      mounted(el, binding) {
        const scroll = new LocomotiveScroll({
          el,
          smooth: true,
          ...binding.value
        });
        el.locomotive = scroll;
      },
      beforeUnmount(el) {
        el.locomotive.destroy();
        el.locomotive = void 0;
      }
    });
  }
});
