import AOS from 'aos';
import 'aos/dist/aos.css';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    nuxtApp.hook('app:mounted', () => {
      AOS.init({
        once: false, // แอนิเมชันจะเล่นเพียงครั้งเดียว
        easing: 'linear',
      });
    });
  }
});