export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=10, shrink-to-fit=no"},{"name":"google-site-verification","content":""},{"name":"title","content":"ShareInvestor Thailand"},{"name":"description","content":"ShareInvestor's expert services in Bangkok, Thailand: Online IR Services, นักลงทุนสัมพันธ์, Tailored Web Design, รายงานประจำปี, IR Web Design"},{"name":"image","content":"https://www.shareinvestorthailand.com/storage/si-og.jpg"},{"property":"og:type","content":"website"},{"property":"og:title","content":"ShareInvestor Thailand"},{"property":"og:description","content":"ShareInvestor's expert services in Bangkok, Thailand: Online IR Services, นักลงทุนสัมพันธ์, Tailored Web Design, รายงานประจำปี, IR Web Design"},{"property":"og:image","content":"https://www.shareinvestorthailand.com/storage/si-og.jpg"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"property":"og:image:alt","content":"ShareInvestor Thailand"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:title","content":"ShareInvestor Thailand"},{"name":"twitter:description","content":"ShareInvestor's expert services in Bangkok, Thailand: Online IR Services, นักลงทุนสัมพันธ์, Tailored Web Design, รายงานประจำปี, IR Web Design"},{"name":"twitter:image","content":"https://www.shareinvestorthailand.com/storage/si-og.jpg"}],"link":[],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"prefetch":true,"prefetchOn":{"visibility":false,"interaction":true},"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = true