
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93T5mXkArHxgMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/[...slug].vue?macro=true";
import { default as about_45usOi8c0c0RWLMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/about-us.vue?macro=true";
import { default as _91_46_46_46id_93JVjfvCZEsVMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/[...id].vue?macro=true";
import { default as indexIOkqGSyYl4Meta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/index.vue?macro=true";
import { default as _91_46_46_46id_93tK4893PMcuMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/[...id].vue?macro=true";
import { default as index2AamjAwai5Meta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/index.vue?macro=true";
import { default as life_45siImhKZMtxpsMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/life-si.vue?macro=true";
import { default as online_45application_45formV7gEXTrodhMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/online-application-form.vue?macro=true";
import { default as contact_45uscNJTN2TATzMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/contact-us.vue?macro=true";
import { default as homeaSQEtQBXd1Meta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/home.vue?macro=true";
import { default as indexKblSl3K6YYMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/index.vue?macro=true";
import { default as intro_45page0hvCSzX3ReMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/intro-page.vue?macro=true";
import { default as our_45portfoliop2C8czxh55Meta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-portfolio.vue?macro=true";
import { default as _91_46_46_46slug_933MGIC692EOMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-product-and-service/[...slug].vue?macro=true";
import { default as page_45not_45foundltS3lfAB3HMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/page-not-found.vue?macro=true";
import { default as searchnOKTLXB8GKMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/search.vue?macro=true";
import { default as sitemap9KIV62wCteMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/sitemap.vue?macro=true";
import { default as component_45stubwz4fkBnc8CMeta } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwz4fkBnc8C } from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/[...slug].vue")
  },
  {
    name: "slug___th",
    path: "/th/:slug(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/[...slug].vue")
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/about-us.vue")
  },
  {
    name: "about-us___th",
    path: "/th/about-us",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/about-us.vue")
  },
  {
    name: "blogs-id___en",
    path: "/en/blogs/:id(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/[...id].vue")
  },
  {
    name: "blogs-id___th",
    path: "/th/blogs/:id(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/[...id].vue")
  },
  {
    name: "blogs___en",
    path: "/en/blogs",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/index.vue")
  },
  {
    name: "blogs___th",
    path: "/th/blogs",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/blogs/index.vue")
  },
  {
    name: "careers-available-jobs-id___en",
    path: "/en/careers/available-jobs/:id(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/[...id].vue")
  },
  {
    name: "careers-available-jobs-id___th",
    path: "/th/careers/available-jobs/:id(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/[...id].vue")
  },
  {
    name: "careers-available-jobs___en",
    path: "/en/careers/available-jobs",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/index.vue")
  },
  {
    name: "careers-available-jobs___th",
    path: "/th/careers/available-jobs",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/available-jobs/index.vue")
  },
  {
    name: "careers-life-si___en",
    path: "/en/careers/life-si",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/life-si.vue")
  },
  {
    name: "careers-life-si___th",
    path: "/th/careers/life-si",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/life-si.vue")
  },
  {
    name: "careers-online-application-form___en",
    path: "/en/careers/online-application-form",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/online-application-form.vue")
  },
  {
    name: "careers-online-application-form___th",
    path: "/th/careers/online-application-form",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/careers/online-application-form.vue")
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/contact-us.vue")
  },
  {
    name: "contact-us___th",
    path: "/th/contact-us",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/contact-us.vue")
  },
  {
    name: "home___en",
    path: "/en/home",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/home.vue")
  },
  {
    name: "home___th",
    path: "/th/home",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/home.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexKblSl3K6YYMeta || {},
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexKblSl3K6YYMeta || {},
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/index.vue")
  },
  {
    name: "intro-page___en",
    path: "/en/intro-page",
    meta: intro_45page0hvCSzX3ReMeta || {},
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/intro-page.vue")
  },
  {
    name: "intro-page___th",
    path: "/th/intro-page",
    meta: intro_45page0hvCSzX3ReMeta || {},
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/intro-page.vue")
  },
  {
    name: "our-portfolio___en",
    path: "/en/our-portfolio",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-portfolio.vue")
  },
  {
    name: "our-portfolio___th",
    path: "/th/our-portfolio",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-portfolio.vue")
  },
  {
    name: "our-product-and-service-slug___en",
    path: "/en/our-product-and-service/:slug(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-product-and-service/[...slug].vue")
  },
  {
    name: "our-product-and-service-slug___th",
    path: "/th/our-product-and-service/:slug(.*)*",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/our-product-and-service/[...slug].vue")
  },
  {
    name: "page-not-found___en",
    path: "/en/page-not-found",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/page-not-found.vue")
  },
  {
    name: "page-not-found___th",
    path: "/th/page-not-found",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/page-not-found.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/search.vue")
  },
  {
    name: "search___th",
    path: "/th/search",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/search.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/sitemap.vue")
  },
  {
    name: "sitemap___th",
    path: "/th/sitemap",
    component: () => import("/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/pages/sitemap.vue")
  },
  {
    name: component_45stubwz4fkBnc8CMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubwz4fkBnc8C
  },
  {
    name: component_45stubwz4fkBnc8CMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubwz4fkBnc8C
  },
  {
    name: component_45stubwz4fkBnc8CMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubwz4fkBnc8C
  },
  {
    name: component_45stubwz4fkBnc8CMeta?.name,
    path: "/th-sitemap.xml",
    component: component_45stubwz4fkBnc8C
  }
]