import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("scroll-to-next", {
    mounted(el) {
      let ticking = false; // Prevent multiple scroll events from firing too quickly

      const getSections = () => document.querySelectorAll("[data-section]");

      const getNextSection = (sections) => {
        const currentScroll = window.pageYOffset;
        for (let section of sections) {
          section.classList.remove('scroll-active')
          if (section.offsetTop > currentScroll) {
            return section;
          }
        }
        return null;
      };

      const getPrevSection = (sections) => {
        const currentScroll = window.pageYOffset;
        for (let i = sections.length - 1; i >= 0; i--) {
          if (sections[i].offsetTop < currentScroll) {
            return sections[i];
          }
        }
        return null;
      };

      const handleScroll = (direction) => {
        const sections = getSections();

        if (!ticking) {
          window.requestAnimationFrame(() => {
            if (direction === "down") {
              const nextSection = getNextSection(sections);
              if (nextSection) {
                nextSection.classList.add('scroll-active')
                window.scrollTo({
                  top: nextSection.offsetTop,
                  behavior: "smooth",
                });
              }
            } else if (direction === "up") {
              const prevSection = getPrevSection(sections);
              if (prevSection) {
                window.scrollTo({
                  top: prevSection.offsetTop,
                  behavior: "smooth",
                });
              }
            }
            ticking = false;
          });
          ticking = true;
        }
      };

      // Add the event listener for scroll
      window.addEventListener("wheel", (e) => {
        if (e.deltaY > 0) {
          // Scroll down to the next section
          handleScroll("down");
        } else if (e.deltaY < 0) {
          // Scroll up to the previous section
          handleScroll("up");
        }
      });
    },
  });
});