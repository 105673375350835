<template>
  <!-- Powered By ShareInvestor Thailand -->
  <NuxtLayout>
    <NuxtLoadingBar :duration="1000" />
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
/* ... styles ... */
</style>
