<template>
  <div class="spinner">
    <div class="spinner__circle"></div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
.spinner {

  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 999;

  .spinner__circle {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #FF9C00;
    /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    margin: auto;
    top: 50%;
    position: relative;
  }

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
  
}
</style>